/* eslint-disable no-shadow */
const urlBase = () => {
  const data = localStorage.getItem('server');
  if (data) {
    const server = JSON.parse(data);

    return `http://${server.ip}:${server.port}/datasnap/rest/tsm`;
  }

  return false;
};

export const login = async atendente => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/login`, {
      method: 'POST',
      body: JSON.stringify(atendente),
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const enviarPedido = async pedido => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/pedido`, {
      method: 'POST',
      body: JSON.stringify(pedido),
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const comandas = async () => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/comandas`, {
      method: 'GET',
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const items = async comanda => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/items/${comanda}`, {
      method: 'GET',
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const resumo = async comanda => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/resumo/${comanda}`, {
      method: 'GET',
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const pedidos = async comanda => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/pedidos/${comanda}`, {
      method: 'GET',
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const auditoria = async comanda => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/auditoria/${comanda}`, {
      method: 'GET',
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const categorias = async () => {
  const categorias = localStorage.getItem('categorias');
  if (categorias) return JSON.parse(categorias);

  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/categorias`, {
      method: 'GET',
    }).then(res => res.json());

    if (res.result) {
      const listCategorias = JSON.stringify(res.result);
      localStorage.setItem('categorias', listCategorias);
    }

    return res.result;
  }

  return null;
};

export const produtos = async () => {
  const produtos = localStorage.getItem('produtos');
  if (produtos) return JSON.parse(produtos);

  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/produtos`, {
      method: 'GET',
    }).then(res => res.json());

    if (res.result) {
      const listProdutos = JSON.stringify(res.result);
      localStorage.setItem('produtos', listProdutos);
    }

    return res.result;
  }

  return null;
};

export const bestprodutos = async () => {
  const bestprodutos = localStorage.getItem('bestprodutos');
  if (bestprodutos) return JSON.parse(bestprodutos);

  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/bestsellers`, {
      method: 'GET',
    }).then(res => res.json());

    if (res.result) {
      const listProdutos = JSON.stringify(res.result);
      localStorage.setItem('bestprodutos', listProdutos);
    }

    return res.result;
  }

  return null;
};

export const comentarios = async produto => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/comments/${produto}`, {
      method: 'GET',
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const setComment = async comment => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/comments`, {
      method: 'POST',
      body: JSON.stringify(comment),
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const customers = async customer => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/customers`, {
      method: 'POST',
      body: JSON.stringify(customer),
    }).then(res => res.json());

    return res.result;
  }

  return null;
};

export const setCustomer = async customer => {
  const url = urlBase();

  if (url) {
    const res = await fetch(`${url}/comandas`, {
      method: 'POST',
      body: JSON.stringify(customer),
    }).then(res => res.json());

    return res.result;
  }

  return null;
};
