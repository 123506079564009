import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { pedidos } from '../../../../../../services/api';
import useStyles from './styles';

function Pedidos() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { comanda } = useSelector(state => state.data);

  useEffect(() => {
    const get = async com => {
      try {
        const data = await pedidos(com.comanda_id);
        dispatch({
          type: '@data/COMANDA',
          data: {
            ...com,
            orders: [
              ...data.map(item => {
                return {
                  ...item,
                  momento: format(
                    new Date(item.momento),
                    'dd/MM/yyyy HH:mm:ss',
                    {
                      locale: ptBR,
                    }
                  ),
                };
              }),
            ],
          },
        });
      } catch (error) {
        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    };

    if (comanda && !comanda.orders) get(comanda);
  }, [comanda]);

  return (
    <div className={classes.root}>
      <ul className={classes.orderList}>
        {comanda &&
          comanda.orders &&
          comanda.orders.map(pedido => (
            <li key={pedido.pedido_id} className={classes.orderItem}>
              <div className={classes.orderHeader}>
                <span>{pedido.momento}</span>
                <span>{pedido.autor}</span>
              </div>

              <ul key={pedido.pedido_id} className={classes.list}>
                {pedido.items.map((item, index) => (
                  <li
                    key={JSON.stringify({ ...item, index })}
                    className={classes.listItem}
                  >
                    <span>{item.quantidade}</span>
                    <span>{item.nome}</span>
                  </li>
                ))}
              </ul>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Pedidos;
