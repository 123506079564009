/* eslint-disable camelcase */
/* eslint-disable no-shadow */
export default function comment(
  state = {
    product: null,
    listModal: false,
    createModal: false,
    comment: null,
  },
  action
) {
  switch (action.type) {
    case '@comment/LIST': {
      const { product = null } = action.data;

      if (product) {
        return {
          ...state,
          product,
          listModal: true,
        };
      }

      return state;
    }

    case '@comment/CANCEL': {
      return {
        ...state,
        listModal: false,
        createModal: false,
      };
    }

    case '@comment/CREATE': {
      const { product = null } = action.data;

      if (product) {
        const { produto_id: product_id } = product;
        return {
          ...state,
          comment: {
            product_id,
            name: '',
          },
          createModal: true,
        };
      }

      return state;
    }

    case '@comment/CREATE_CANCEL': {
      return {
        ...state,
        createModal: false,
      };
    }

    default:
      return state;
  }
}
