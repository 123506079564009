/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import copy from '../../../../../assets/copy.svg';
import edit from '../../../../../assets/edit.svg';
import user from '../../../../../assets/user.svg';
import dollar from '../../../../../assets/dollar.svg';

function ComandaSettings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { comandaSettingsModal, comanda } = useSelector(state => state.data);

  const hanldeMenu = () => {
    dispatch({ type: '@data/COMANDA_SETTINGS' });
  };

  return (
    <Drawer anchor="bottom" open={comandaSettingsModal} onClose={hanldeMenu}>
      <div className={classes.root}>
        {comanda && <h1>{`COMANDA ${comanda.numero}`}</h1>}

        <ul className={classes.ul}>
          <div className={classes.btnOptions}>
            <span>ADICIONAR UM CLIENTE</span>
            <img src={user} alt="usuario" />
          </div>
          <div className={classes.btnOptions}>
            <span>APELIDAR</span>
            <img src={edit} alt="editar" />
          </div>
          <div className={classes.btnOptions}>
            <span>FECHAR</span>
            <img src={copy} alt="fechar" />
          </div>
          <div className={classes.btnOptions}>
            <span>RECEBER ITENS</span>
            <img src={dollar} alt="dolar" />
          </div>
          <div className={classes.btnOptions}>
            <span>RECEBER</span>
            <img src={dollar} alt="dolar" />
          </div>
        </ul>
      </div>
    </Drawer>
  );
}

export default ComandaSettings;
