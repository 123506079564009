import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    background: '#fff',
  },
  list: {
    margin: 0,
    padding: 0,
  },
  item: {
    listStyle: 'none',
    marginBottom: '30px',
  },
  header: {
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '15px',
  },
  detail: {
    background: '#eee',
    borderRadius: '10px',
    marginBottom: '20px',
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '14px',

    '& span': {
      marginLeft: '20px',
    },
  },
}));

export default useStyles;
