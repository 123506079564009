/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ArrowBackIos, ShoppingCartOutlined } from '@material-ui/icons';
import { categorias } from '../../../services/api';
import useStyles from './styles';

function Categories() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const { categoriesModal } = useSelector(state => state.data);
  const count = useSelector(state => {
    const { order } = state.order;
    return order ? order.items.length : 0;
  });

  useEffect(() => {
    const get = async () => {
      try {
        const data = await categorias();
        setList(data);
      } catch (error) {
        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    };

    get();
  }, []);

  const hanldeClose = () => {
    dispatch({ type: '@data/CATEGORIES' });
  };

  const hanldeCategory = category => {
    dispatch({ type: '@data/PRODUCTS', data: category });
  };

  const hanldeOrder = () => {
    if (count >= 1) dispatch({ type: '@order/SHOW' });
  };

  return (
    <Drawer anchor="right" open={categoriesModal} onClose={hanldeClose}>
      <div className={classes.root}>
        <ul className={classes.list}>
          {list.map(item => (
            <li
              key={item.categoria_id}
              className={classes.item}
              onClick={() => hanldeCategory(item)}
            >
              <span>{item.nome}</span>
            </li>
          ))}
        </ul>

        <div className={classes.footer}>
          <ArrowBackIos onClick={hanldeClose} />
          <h1>categorias</h1>
          <div className={classes.cart} onClick={hanldeOrder}>
            {count >= 1 && <span>{count}</span>}
            <ShoppingCartOutlined />
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Categories;
