/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import menu from '../../../../assets/menu2.svg';
import deletar from '../../../../assets/delete.svg';
import edit from '../../../../assets/edit.svg';
import useStyles from './styles';

export default function Itens() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);

  const { order } = useSelector(state => state.order);

  const hanldeEdit = () => {
    const item = { ...product };
    dispatch({ type: '@item/SELECTED', data: { product: item } });
    setProduct(null);
  };

  const hanldeRemove = () => {
    const { key } = product;
    dispatch({ type: '@order/REMOVE_ITEM', key });
    enqueueSnackbar(`O item foi removido do pedido`, { variant: 'info' });
    setProduct(null);
  };

  return (
    <>
      <div className={classes.root}>
        <ul>
          {order &&
            order.items.map(item => (
              <li key={item.key} className={classes.item}>
                <div>
                  <span className={classes.spanQuantidade}>{item.amount}</span>
                  <span className={classes.spanName}>{item.nome}</span>
                </div>
                <img
                  src={menu}
                  alt="menu"
                  className={classes.menu}
                  onClick={() => setProduct(item)}
                />
              </li>
            ))}
        </ul>
      </div>

      <Drawer anchor="bottom" open={!!product} onClose={() => setProduct(null)}>
        <div className={classes.listSettings}>
          {product && (
            <>
              <h1>{product.nome}</h1>
              <ul className={classes.ul}>
                <div className={classes.btnOptions} onClick={hanldeEdit}>
                  <span>editar</span>
                  <img src={edit} alt="editar" />
                </div>
                <div className={classes.btnOptions} onClick={hanldeRemove}>
                  <span>remover</span>
                  <img src={deletar} alt="remover" />
                </div>
              </ul>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
}
