import { decode } from 'js-base64';

export default function data(
  state = {
    loading: true,
    token: null,
    auth: false,
    user: null,
    menuModal: false,
    comanda: null,
    comandaModal: false,
    comandaSettingsModal: false,
    categoriesModal: false,
    productsModal: false,
    category: null,
    orderModal: false,
    itemModal: false,
    item: null,
    commentModal: false,
  },
  action
) {
  switch (action.type) {
    case '@data/LOADING': {
      return {
        ...state,
        loading: !state.loading,
      };
    }

    case '@data/LOAD': {
      const { token = null } = action.data;

      if (token) {
        const dec = decode(token);
        const [ip, port] = dec.split('|');
        const server = {
          ip,
          port,
        };

        const serverStorage = localStorage.getItem('server');
        if (!serverStorage) {
          localStorage.setItem('server', JSON.stringify(server));
        }
      }

      const userStorage = localStorage.getItem('user');
      const user = userStorage ? JSON.parse(userStorage) : null;

      return {
        ...state,
        loading: false,
        token,
        user,
        auth: !!user,
      };
    }

    case '@data/AUTH': {
      const { data: user } = action;

      if (user) {
        localStorage.setItem('user', JSON.stringify(user));

        return {
          ...state,
          auth: true,
          user,
        };
      }

      return state;
    }

    case '@data/SINGOUT': {
      localStorage.removeItem('user');
      localStorage.removeItem('categorias');
      localStorage.removeItem('produtos');
      return {
        ...state,
        loading: false,
        auth: false,
        user: null,
        menuModal: false,
      };
    }

    case '@data/MENU': {
      return {
        ...state,
        menuModal: !state.menuModal,
      };
    }

    case '@data/COMANDA': {
      const { data: comanda = null } = action;

      return {
        ...state,
        comanda,
        comandaModal: !!comanda,
      };
    }

    case '@data/COMANDA_SETTINGS': {
      const { data: comanda = null } = action;

      return {
        ...state,
        comanda,
        comandaSettingsModal: !!comanda,
      };
    }

    case '@data/ORDER': {
      return {
        ...state,
        orderModal: !state.orderModal,
      };
    }

    case '@data/CATEGORIES': {
      return {
        ...state,
        categoriesModal: !state.categoriesModal,
      };
    }

    case '@data/PRODUCTS': {
      const { data: category = null } = action;
      return {
        ...state,
        category,
        productsModal: !!category,
      };
    }

    case '@data/ITEM': {
      const { data: item = null } = action;
      return {
        ...state,
        item,
        itemModal: !!item,
      };
    }

    case '@data/COMMENT': {
      return {
        ...state,
        commentModal: !state.commentModal,
      };
    }

    case '@data/SET_COMMENT': {
      const { comment = null } = action.data;
      return {
        ...state,
        item: { ...state.item, comment },
        loading: false,
        commentModal: false,
      };
    }

    default:
      return state;
  }
}
