/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import back from '../../../../../assets/back.svg';
import Resumo from './Resumo';
import Items from './Items';
import Pedidos from './Pedidos';
import Auditoria from './Auditoria';
import Nunber from '../../../../../components/Nunber';

import useStyles from './styles';

function Comanda() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuSelect, setMenuSelect] = useState('resumo');
  const { comandaModal, comanda } = useSelector(state => state.data);

  const hanldeClose = () => {
    dispatch({ type: '@data/COMANDA' });
    setMenuSelect('resumo');
  };

  const removeClassList = () => {
    const elements = document.getElementsByClassName('yes');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('yes');
    }
  };

  const handleMenusFunc = async e => {
    const element = e.target;
    setMenuSelect(element.id);
    await removeClassList();
    element.classList.add('yes');
  };

  function Render() {
    if (menuSelect === 'resumo') {
      return <Resumo />;
    }
    if (menuSelect === 'itens') {
      return <Items />;
    }
    if (menuSelect === 'pedidos') {
      return <Pedidos />;
    }
    if (menuSelect === 'auditoria') {
      return <Auditoria />;
    }
  }

  return (
    <Drawer anchor="right" open={comandaModal} onClose={hanldeClose}>
      <div>
        <div className={classes.header}>
          <img src={back} alt="menu" onClick={hanldeClose} />
          <h1>COMANDA</h1>
        </div>

        <div className={classes.infosComanda}>
          {comanda && (
            <div className={classes.fields}>
              <Nunber comanda={comanda} />
              <div className={classes.info}>
                <h1>{comanda.cliente}</h1>
                <span>{`pedidos: ${comanda.pedidos}`}</span>
                <span>{`último pedido: ${comanda.timeFomatted}`}</span>
              </div>
            </div>
          )}
        </div>

        <ul className={classes.ulMenu}>
          <li className="yes" id="resumo" onClick={handleMenusFunc}>
            RESUMO
          </li>
          <li id="itens" onClick={handleMenusFunc}>
            ITENS
          </li>
          <li id="pedidos" onClick={handleMenusFunc}>
            PEDIDOS
          </li>
          <li id="auditoria" onClick={handleMenusFunc}>
            AUDITORIA
          </li>
        </ul>
      </div>
      <Render />
    </Drawer>
  );
}

export default Comanda;
