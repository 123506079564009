import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  content: {
    margin: 0,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    width: 'calc(100vw - 40px)',
    margin: 0,
    padding: '20px',
    background: '#002C70',
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 400,
    borderRadius: '10px',
    marginBottom: '30px',
  },
  amount: {
    width: 'calc(100vw - 40px)',
    margin: 0,
    padding: '20px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #eee',
    borderRadius: '10px',
    marginBottom: '30px',

    '& span': {
      fontSize: '1.5rem',
      fontWeight: 400,
    },

    '& svg': {
      fontSize: '2rem',
    },
  },
  plus: {
    width: 'calc(100vw - 40px)',
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',

    '& span': {
      padding: '15px 30px',
      border: '2px solid #eee',
      borderRadius: '10px',
      fontSize: '1.25rem',
      fontWeight: 400,
    },
  },
  comment: {
    margin: 0,
    padding: '15px',
    textAlign: 'center',
    background: '#FF7A01',
    color: '#fff',
    fontFamily: 'Montserrat',
    fontSize: '0.8rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
  },
  footer: {
    margin: 0,
    padding: 0,
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.25)',
    background: '#fff',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    fontSize: '16px',
    letterSpacing: '1px',

    '& div': {
      width: '50vw',
      padding: '20px',
      color: '#fff',
    },
  },
  cancel: {
    background: '#ff0000',
  },
  confirm: {
    background: '#228844',
  },
});

export default useStyles;
