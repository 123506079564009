/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Replay } from '@material-ui/icons';
import { formatPrice } from '../../../../utils/format';
import Number from '../../../../components/Nunber';
import { comandas } from '../../../../services/api';

import useStyles from './styles';

function Body() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [replay, setReplay] = useState(0);
  const [list, setList] = useState([]);

  useEffect(() => {
    const get = async () => {
      dispatch({ type: '@data/LOADING' });

      try {
        const data = await comandas();
        if (data) {
          setList([
            ...data.map(item => {
              const total = item.subtotal + item.taxa_servico;
              return {
                ...item,
                total,
                formattedSubtotal: formatPrice(item.subtotal),
                formattedTaxa: formatPrice(item.taxa_servico),
                formattedTotal: formatPrice(total),
                timeFomatted: format(new Date(item.ultimo_pedido), "HH'h'mm", {
                  locale: ptBR,
                }),
              };
            }),
          ]);
        } else setList([]);

        dispatch({ type: '@data/LOADING' });
      } catch (error) {
        dispatch({ type: '@data/LOADING' });

        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    };

    get();
  }, [replay]);

  const hanldeComanda = comanda => {
    dispatch({ type: '@data/COMANDA', data: comanda });
  };

  const hanldeComandaSettings = comanda => {
    dispatch({ type: '@data/COMANDA_SETTINGS', data: comanda });
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.infoComand}>
          <span>{`${list.length} comandas`}</span>
          <Replay
            fontSize="default"
            onClick={() => setReplay(prev => prev + 1)}
          />
        </div>
        <ul className={classes.ul}>
          {list.map(item => (
            <div key={item.comanda_id} className={classes.item}>
              <div
                className={classes.fields}
                onClick={() => hanldeComanda(item)}
              >
                <Number comanda={item} />
                <div className={classes.info}>
                  <h1>{item.cliente}</h1>
                  <span>{`pedidos: ${item.pedidos}`}</span>
                  <span>{`último pedido: ${item.timeFomatted}`}</span>
                </div>
                <div className={classes.price}>
                  <span>{item.formattedTotal}</span>
                </div>
              </div>
              {/* <div
                className={classes.menu}
                onClick={() => hanldeComandaSettings(item)}
              >
                <img src={menu} alt="menu" />
              </div> */}
            </div>
          ))}
        </ul>
      </div>
      <button
        type="button"
        className={classes.add}
        onClick={() => {
          dispatch({ type: '@data/CATEGORIES' });
        }}
      >
        pedir
      </button>
    </>
  );
}

export default Body;
