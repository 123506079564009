import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  header: {
    position: 'sticky',
    width: '100vw',
    boxSizing: 'border-box',
    padding: '20px 20px',
    display: 'flex',
    zIndex: 1000,
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
    justifyContent: 'space-betweeen',

    '& h1': {
      padding: 0,
      position: 'relative',
      margin: '0 auto',
      fontWeight: 500,
      color: '#FFA827',
      fontFamily: 'Montserrat',
      fontSize: '18px',
      textTransform: 'uppercase',
    },
  },
  infosComanda: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  fields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-betweeen',
    flex: 1,
  },
  number: {
    width: '70px',
    height: '70px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#eee',
    color: '#000',
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },
  info: {
    marginLeft: '15px',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',

    '& h1': {
      fontSize: '16px',
      margin: 0,
      padding: 0,
      fontWeight: 600,
    },
    '& span': {
      textTransform: 'capitalize',
    },
    flex: 1,
  },
  ulMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    margin: 0,
    borderBottom: '1px solid #eee',

    '& li': {
      listStyle: 'none',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      color: '#c4c4c4',
    },
    '& li.yes': {
      color: '#FFA827',
    },
    '& li.yes::after': {
      transition: 'all .5s',
      content: '',
      width: '100%',
      display: 'block',
      position: 'relative',
      height: '2px',
      top: '16px',
      background: '#FFA827',
    },
  },
}));

export default useStyles;
