export default function order(
  state = {
    order: null,
    orderModal: false,
  },
  action
) {
  switch (action.type) {
    case '@order/CREATE': {
      if (state.order) return state;
      const { token } = action.data;
      const id = String(`${token}`).toUpperCase();
      const storageOrder = localStorage.getItem(id);
      const parsedOrder = storageOrder ? JSON.parse(storageOrder) : null;

      if (parsedOrder) {
        return {
          order: parsedOrder,
          orderModal: false,
        };
      }

      const recentOrder = {
        id,
        items: [],
      };

      localStorage.setItem(id, JSON.stringify(recentOrder));

      return {
        order: recentOrder,
        orderModal: false,
      };
    }

    case '@order/CONFIRMED': {
      const { token } = action.data;
      const id = String(`${token}`).toUpperCase();

      const recentOrder = {
        id,
        items: [],
      };

      localStorage.setItem(id, JSON.stringify(recentOrder));

      return {
        order: recentOrder,
        orderModal: false,
      };
    }

    case '@order/ADD_ITEM': {
      if (action.data) {
        const deprecatedItem = state.order.items.find(
          item => item.key === action.data.key
        );

        if (deprecatedItem) {
          const items = state.order.items.map(item => {
            if (item.key === action.data.key) return action.data;

            return item;
          });

          const recentOrder = { ...state.order, items };

          localStorage.setItem(state.order.id, JSON.stringify(recentOrder));

          return { ...state, order: recentOrder };
        }

        const items = [...state.order.items, action.data];

        const recentOrder = { ...state.order, items };

        localStorage.setItem(state.order.id, JSON.stringify(recentOrder));

        return { ...state, order: recentOrder };
      }

      return state;
    }

    case '@order/REMOVE_ITEM': {
      const items = state.order.items.filter(item => item.key !== action.key);

      const recentOrder = { ...state.order, items };

      localStorage.setItem(state.order.id, JSON.stringify(recentOrder));

      return {
        ...state,
        order: recentOrder,
        orderModal: items.length >= 1,
      };
    }

    case '@order/SHOW': {
      return {
        ...state,
        orderModal: true,
      };
    }

    case '@order/CLOSE': {
      return {
        ...state,
        orderModal: false,
      };
    }

    default:
      return state;
  }
}
