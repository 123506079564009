import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px 20px 60px',
    background: '#fff',
    transform: 'translateY(-15px)',
    zIndex: 1000,
    borderRadius: '20px 20px 0 0',
    '& svg': {
      color: '#FF7A01',
    },
  },
  infoComand: {
    margin: 0,
    padding: '0 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },
  ul: {
    padding: '0px',
    margin: '0px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 20px',
  },
  fields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-betweeen',
    flex: 1,
  },
  info: {
    marginLeft: '15px',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',

    '& h1': {
      fontSize: '16px',
      margin: 0,
      padding: 0,
      fontWeight: 600,
    },
    '& span': {
      textTransform: 'capitalize',
    },
    flex: 1,
  },
  price: {
    '& span': {
      textAlign: 'right',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '16px',
    },
  },
  menu: {
    minWidth: '30px',
    display: 'flex',
    justifyContent: 'center',
  },
  add: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    width: '100vw',
    padding: '20px 0',
    border: 'none',
    filter: 'drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25))',
    background: '#FF7A01',
    color: '#fff',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
