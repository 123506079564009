import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    background: '#fff',
  },
  orderList: {
    margin: 0,
    padding: 0,
  },
  orderItem: {
    listStyle: 'none',
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 600,
      marginBottom: '20px',
    },
  },
  list: {
    margin: 0,
    padding: 0,

    '& li': {
      background: '#eee',
      borderRadius: '10px',
      marginBottom: '20px',
      listStyle: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '14px',
    },
  },
}));

export default useStyles;
