import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    '& ul': {
      margin: 0,
      padding: 0,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    background: '#eeee',
    borderRadius: '10px',
    marginBottom: '20px',

    '& div': {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Montserrat',
    },
  },
  spanQuantidade: {
    minWidth: '20px',
    maxWidth: 'max-content',
  },
  spanName: {
    padding: '0 15px',
    flex: 1,
  },
  menu: {
    maxHeight: '20px',
  },
  listSettings: {
    width: '100%',
    padding: '30px 20px',
    fontFamily: 'Montserrat',

    '& h1': {
      margin: 0,
      padding: 0,
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: 600,
      marginBottom: '20px',
    },
  },
  ul: {
    margin: 0,
    padding: 0,

    '& span': {
      textTransform: 'uppercase',
    },
  },
  btnOptions: {
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #eee',
    cursor: 'pointer',
  },
}));

export default useStyles;
