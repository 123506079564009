/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Remove, Add } from '@material-ui/icons';

import useStyles from './styles';

function Products() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { modalProduct, product } = useSelector(state => state.item);

  const hanldeClose = () => {
    dispatch({ type: '@item/CANCEL' });
  };

  const hanldeConfirm = () => {
    dispatch({ type: '@order/ADD_ITEM', data: { ...product } });

    enqueueSnackbar(`O item foi incluido ao pedido`, { variant: 'info' });

    dispatch({ type: '@item/CANCEL' });
  };

  return (
    <Drawer anchor="right" open={modalProduct} onClose={hanldeClose}>
      <div className={classes.root}>
        {product && (
          <div className={classes.content}>
            <div className={classes.title}>
              <span>{`${product.codigo} - ${product.nome}`}</span>
            </div>
            <div className={classes.amount}>
              <Remove
                onClick={() => {
                  if (product.amount >= 2)
                    dispatch({
                      type: '@item/CHANGE_AMOUNT',
                      data: {
                        amount: product.amount - 1,
                      },
                    });
                }}
              />
              <span>{product.amount}</span>
              <Add
                onClick={() => {
                  dispatch({
                    type: '@item/CHANGE_AMOUNT',
                    data: {
                      amount: product.amount + 1,
                    },
                  });
                }}
              />
            </div>
            <div className={classes.plus}>
              <span
                onClick={() => {
                  dispatch({
                    type: '@item/CHANGE_AMOUNT',
                    data: {
                      amount: product.amount + 5,
                    },
                  });
                }}
              >
                + 5
              </span>
              <span
                onClick={() => {
                  dispatch({
                    type: '@item/CHANGE_AMOUNT',
                    data: {
                      amount: product.amount + 10,
                    },
                  });
                }}
              >
                + 10
              </span>
              <span
                onClick={() => {
                  dispatch({
                    type: '@item/CHANGE_AMOUNT',
                    data: {
                      amount: product.amount + 15,
                    },
                  });
                }}
              >
                + 15
              </span>
            </div>

            {product.comment ? (
              <div className={classes.comment}>{product.comment.name}</div>
            ) : (
              <div
                className={classes.comment}
                onClick={() => {
                  dispatch({ type: '@comment/LIST', data: { product } });
                }}
              >
                adicionar um comentário
              </div>
            )}
          </div>
        )}

        <div className={classes.footer}>
          <div onClick={hanldeClose} className={classes.cancel}>
            cancelar
          </div>
          <div onClick={hanldeConfirm} className={classes.confirm}>
            confirmar
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Products;
