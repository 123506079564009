/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ArrowBackIos } from '@material-ui/icons';
import { produtos } from '../../../services/api';
import useStyles from './styles';

function Products() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const { productsModal, category } = useSelector(state => state.data);

  useEffect(() => {
    const get = async cat => {
      try {
        const data = await produtos();
        setList([...data.filter(f => f.categoria_id === cat.categoria_id)]);
      } catch (error) {
        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    };

    if (category) get(category);
  }, [category]);

  const hanldeOnClose = () => {
    dispatch({ type: '@data/PRODUCTS' });
  };

  const hanldeItem = product => {
    dispatch({ type: '@item/SELECTED', data: { product } });
  };

  return (
    <Drawer anchor="right" open={productsModal} onClose={hanldeOnClose}>
      <div className={classes.root}>
        <ul className={classes.list}>
          {list.map(item => (
            <li
              key={item.produto_id}
              className={classes.item}
              onClick={() => hanldeItem(item)}
            >
              <span>{`${item.codigo} - ${item.nome}`}</span>
            </li>
          ))}
        </ul>

        <div className={classes.footer}>
          <ArrowBackIos onClick={hanldeOnClose} />
          <h1>{category && category.nome}</h1>
        </div>
      </div>
    </Drawer>
  );
}

export default Products;
