/* eslint-disable no-shadow */
export default function item(
  state = {
    product: null,
    modalProduct: false,
  },
  action
) {
  switch (action.type) {
    case '@item/SELECTED': {
      const { product } = action.data;

      if (product) {
        return {
          ...state,
          product: {
            ...product,
            key: product.key || new Date().getTime(),
            amount: product.amount || 1,
            comment: product.comment || null,
          },
          modalProduct: true,
        };
      }

      return state;
    }

    case '@item/CANCEL': {
      return {
        modalProduct: false,
      };
    }

    case '@item/CHANGE_AMOUNT': {
      const { amount = 1 } = action.data;
      return {
        ...state,
        product: {
          ...state.product,
          amount,
        },
      };
    }

    case '@item/SET_COMMENT': {
      const { comment = null } = action.data;

      return {
        ...state,
        product: {
          ...state.product,
          comment,
        },
      };
    }

    default:
      return state;
  }
}
