import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '30px 20px',
    fontFamily: 'Montserrat',

    '& h1': {
      margin: 0,
      padding: 0,
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: 600,
      marginBottom: '20px',
    },
  },
  ul: {
    margin: 0,
    padding: 0,
  },
  btnOptions: {
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #eee',
    cursor: 'pointer',
  },
}));

export default useStyles;
