import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
    width: '100vw',
  },
  keyboard: {
    margin: 0,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    margin: 0,
    padding: 0,
    display: 'flex',
  },
  number: {
    minWidth: '60px',
    height: '60px',
    margin: '5px',
    padding: '10px',
    borderRadius: '20px',
    background: '#fff',
    border: '2px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat',
    color: '#010D1F',
    fontSize: '26px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  cancel: {
    width: '60px',
    height: '60px',
    margin: '5px',
    padding: 0,
    borderRadius: '20px',
    background: '#ff0000',
    border: '1px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat',
    color: '#fff',
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  enter: {
    width: '60px',
    height: '60px',
    margin: '5px',
    padding: 0,
    borderRadius: '20px',
    background: '#298e4f',
    border: '1px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat',
    color: '#fff',
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  comanda: {
    margin: 0,
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
}));

export default useStyles;
