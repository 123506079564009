/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { PermIdentity } from '@material-ui/icons';
import useStyles from './styles';

function Menu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { menuModal, user } = useSelector(state => state.data);

  const hanldeMenu = () => {
    dispatch({ type: '@data/MENU' });
  };

  const handleSignOut = () => {
    dispatch({ type: '@data/SINGOUT' });
  };

  return user ? (
    <Drawer anchor="left" open={menuModal} onClose={hanldeMenu}>
      <div className={classes.root}>
        <div className={classes.profile}>
          <PermIdentity />

          <div className={classes.infos}>
            <h1>{user.nome}</h1>
            <span>{user.vendedor_id}</span>
          </div>
        </div>
        <button
          type="button"
          onClick={handleSignOut}
          className={classes.submit}
        >
          sair
        </button>
      </div>
    </Drawer>
  ) : null;
}

export default Menu;
