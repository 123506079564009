import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  list: {
    margin: 0,
    padding: '20px 20px 110px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gridGap: '15px',
  },
  item: {
    margin: 0,
    listStyle: 'none',
    background: '#FF7A01',
    borderRadius: '10px',
    color: '#fff',
    fontWeight: 400,
    fontFamily: 'Montserrat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontSize: '1rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
    padding: '10px',
    minHeight: '80px',
    textAlign: 'center',
  },
  footer: {
    boxShadow: '0px -1px 6px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    background: '#fff',
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& h1': {
      padding: 0,
      fontWeight: 400,
      color: '#FF7A01',
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    '& svg': {
      color: '#FF7A01',
    },
  },
  cart: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  modal: {
    margin: 0,
    padding: 0,
  },
  header: {
    width: '100vw',
    padding: '20px 20px 5px 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 500,
  },
  comment: {
    width: '100vw',
    padding: '0 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& textarea': {
      width: '100%',
      padding: '10px',
      filter: 'drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25))',
      fontFamily: 'Montserrat',
      textTransform: 'uppercase',
      fontSize: '0.85rem',
      border: '1px solid #eee',
      borderRadius: '8px',
    },
    '& textarea:focus': {
      border: '1px solid #eee',
      outlineColor: '#eee',
      borderRadius: '8px',
    },
  },
  confirm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    padding: '20px 0',
    border: 'none',
    filter: 'drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25))',
    background: '#228844',
    color: '#fff',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
});

export default useStyles;
