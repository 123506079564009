/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import Items from './Items';
import Send from './Send';
import useStyles from './styles';

function Order() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [send, setSend] = useState(false);
  const { order, orderModal } = useSelector(state => state.order);

  const hanldeClose = () => {
    dispatch({ type: '@order/CLOSE' });
  };

  return (
    <>
      <Drawer anchor="right" open={orderModal} onClose={hanldeClose}>
        <div className={classes.root}>
          {order && <Items />}
          <div className={classes.footer}>
            <ArrowBackIos onClick={hanldeClose} />
            <h1 onClick={() => setSend(true)}>enviar pedido</h1>
          </div>
        </div>
      </Drawer>

      <Send send={send} setSend={setSend} />
    </>
  );
}

export default Order;
