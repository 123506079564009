/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import logo from '../../assets/logo.png';
import engrenagem from '../../assets/engrenagem.svg';
import { login } from '../../services/api';

import Server from './Server';
import useStyles from './styles';

function Sign() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [server, setServer] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const handleOnSubmit = async e => {
    e.preventDefault();
    dispatch({ type: '@data/LOADING' });
    try {
      const user = { id, password };
      const data = await login(user);

      if (data) {
        dispatch({ type: '@data/AUTH', data });
      } else {
        enqueueSnackbar('Id ou senha incorretos', { variant: 'error' });
      }

      dispatch({ type: '@data/LOADING' });
    } catch (error) {
      dispatch({ type: '@data/LOADING' });

      enqueueSnackbar(
        'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
        { variant: 'error' }
      );
    }
  };

  return (
    <>
      <div className={classes.root}>
        <img className={classes.logo} src={logo} alt="" />
        <div>
          <form onSubmit={handleOnSubmit} className={classes.sign}>
            <input
              className={classes.input}
              type="text"
              placeholder="ID"
              value={id}
              onChange={event => setId(event.target.value)}
            />
            <input
              className={classes.input}
              type="password"
              placeholder="SENHA"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
            <input className={classes.submit} type="submit" value="ACESSAR" />
          </form>

          <div className={classes.btnServer} onClick={() => setServer(true)}>
            <img src={engrenagem} alt="engrenagem" />
            <span>Configurar servidor</span>
          </div>
        </div>
      </div>
      <Server open={server} handleClose={setServer} />
    </>
  );
}

export default Sign;
