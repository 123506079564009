/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sign from '../../components/Sign';
import Dashboard from './Dashboard';

function Store({ match }) {
  const { params } = match;
  const { token = 'MTkyLjE2OC4xLjEwMXw4MDgw' } = params;
  const dispatch = useDispatch();

  const { auth } = useSelector(state => state.data);

  useEffect(() => {
    dispatch({ type: '@data/LOAD', data: { token } });
    dispatch({ type: '@order/CREATE', data: { token } });
  }, []);

  return auth ? <Dashboard /> : <Sign />;
}

export default Store;
