import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { resumo } from '../../../../../../services/api';
import { formatPrice } from '../../../../../../utils/format';

export default function Resumo() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { comanda } = useSelector(state => state.data);

  useEffect(() => {
    const get = async com => {
      try {
        const data = await resumo(com.comanda_id);
        dispatch({
          type: '@data/COMANDA',
          data: {
            ...com,
            resumo: [
              ...data.map(item => {
                return {
                  ...item,
                  formattedSubtotal: formatPrice(item.subtotal),
                };
              }),
            ],
          },
        });
      } catch (error) {
        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    };

    if (comanda && !comanda.resumo) get(comanda);
  }, [comanda]);

  return (
    <div>
      <ul className={classes.listProds}>
        {comanda &&
          comanda.resumo &&
          comanda.resumo.map(item => (
            <div key={JSON.stringify(item)} className={classes.itemListProds}>
              <span className={classes.spanQuantidade}>{item.quantidade}</span>
              <span className={classes.spanName}>{item.item}</span>
              <span className={classes.spanSubtotal}>
                {item.formattedSubtotal}
              </span>
            </div>
          ))}
      </ul>
      <div className={classes.totals}>
        {comanda && (
          <>
            <p>
              Subtotal
              <span>{comanda.formattedSubtotal}</span>
            </p>
            <p>
              Taxa
              <span>{comanda.formattedTaxa}</span>
            </p>
            <p className={classes.tot}>
              Total
              <span>{comanda.formattedTotal}</span>
            </p>
          </>
        )}
      </div>
    </div>
  );
}
