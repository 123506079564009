import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  listProds: {
    margin: 0,
    padding: '20px 20px 160px',
  },
  itemListProds: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#eee',
    borderRadius: '10px',
    padding: '15px',

    '& span': {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  spanQuantidade: {
    minWidth: '20px',
    maxWidth: 'max-content',
  },
  spanSubtotal: {
    minWidth: 'max-content',
  },
  spanName: {
    padding: '0 15px',
    flex: 1,
  },
  totals: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    background: '#fff',
    width: '100%',
    padding: '20px',

    '& p': {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: 'Montserrat',
      fontWeight: 500,
    },
  },
  tot: {
    fontSize: '18px',
  },
}));

export default useStyles;
