import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Bookmark,
  Delete,
  Redo,
  Undo,
  MoneyOff,
  AttachMoney,
  Add,
} from '@material-ui/icons';

import { auditoria } from '../../../../../../services/api';
import useStyles from './styles';

function Auditoria() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { comanda } = useSelector(state => state.data);

  useEffect(() => {
    const get = async com => {
      try {
        const data = await auditoria(com.comanda_id);
        dispatch({
          type: '@data/COMANDA',
          data: {
            ...com,
            audits: [
              ...data.map(item => {
                return {
                  ...item,
                  momento: format(
                    new Date(item.momento),
                    'dd/MM/yyyy HH:mm:ss',
                    {
                      locale: ptBR,
                    }
                  ),
                };
              }),
            ],
          },
        });
      } catch (error) {
        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    };

    if (comanda && !comanda.audits) get(comanda);
  }, [comanda]);

  const returnIcon = icon => {
    switch (icon) {
      case 'A':
        return <Bookmark />;
      case 'B':
        return <Bookmark />;
      case 'C':
        return <Delete />;
      case 'D':
        return <Redo />;
      case 'F':
        return <Bookmark />;
      case 'O':
        return <Undo />;
      case 'P':
        return <MoneyOff />;
      case 'R':
        return <AttachMoney />;
      case 'V':
        return <Add />;
      default:
        return <Bookmark />;
    }
  };

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {comanda &&
          comanda.audits &&
          comanda.audits.map((item, index) => (
            <li key={JSON.stringify(index)} className={classes.item}>
              <div className={classes.header}>
                <span>{item.momento}</span>
                <span>{item.autor}</span>
              </div>
              <div className={classes.detail}>
                {returnIcon(item.tipo)}
                <span>{item.descricao}</span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Auditoria;
