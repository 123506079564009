import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '40px',
    padding: '0 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat',
    marginBottom: '40px',

    '& span': {
      width: '100%',
      textAlign: 'center',
      fontWeight: 500,
    },
  },
  header: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px 30px',
    display: 'flex',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',

    '& h1': {
      padding: 0,
      position: 'relative',
      margin: '0 auto',
      fontWeight: 400,
      color: '#FF7A01',
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
  },
  sign: {
    marginTop: '20px',
  },
  input: {
    boxSizing: 'border-box',
    padding: '0 15px',
    borderRadius: '10px',
    border: 'none',
    background: '#eee',
    width: '100%',
    height: '60px',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontSize: '15px',
    marginBottom: '20px',
  },
  submit: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    border: 'none',
    background: '#FF7A01',
    color: '#fff',
    width: '100%',
    height: '60px',
    fontWeight: 400,
    fontFamily: 'Montserrat',
    fontSize: '15px',
    letterSpacing: '1px',
    marginBottom: '20px',
  },
}));

export default useStyles;
