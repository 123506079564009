/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import menu from '../../../../../../assets/menu2.svg';
import deletar from '../../../../../../assets/delete.svg';
import transfer from '../../../../../../assets/transfer.svg';
import { items } from '../../../../../../services/api';
import useStyles from './styles';

export default function Itens() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);

  const { comanda } = useSelector(state => state.data);

  useEffect(() => {
    const get = async com => {
      try {
        const data = await items(com.comanda_id);
        dispatch({
          type: '@data/COMANDA',
          data: {
            ...com,
            items: data,
          },
        });
      } catch (error) {
        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    };

    if (comanda && !comanda.items) get(comanda);
  }, [comanda]);

  return (
    <>
      <div className={classes.root}>
        <ul>
          {comanda &&
            comanda.items &&
            comanda.items.map(item => (
              <li key={item.item_id} className={classes.item}>
                <div>
                  <span className={classes.spanQuantidade}>
                    {item.quantidade}
                  </span>
                  <span className={classes.spanName}>{item.item}</span>
                </div>
                {/* <img
                  src={menu}
                  alt="menu"
                  className={classes.menu}
                  onClick={() => setProduct(item)}
                /> */}
              </li>
            ))}
        </ul>
      </div>

      <Drawer anchor="bottom" open={!!product} onClose={() => setProduct(null)}>
        <div className={classes.listSettings}>
          {product && (
            <>
              <h1>{product.item}</h1>
              <ul className={classes.ul}>
                <div className={classes.btnOptions}>
                  <span>transferir item</span>
                  <img src={transfer} alt="transferir item" />
                </div>
                <div className={classes.btnOptions}>
                  <span>remover item</span>
                  <img src={deletar} alt="remover item" />
                </div>
              </ul>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
}
