/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ArrowBackIos } from '@material-ui/icons';
import { comentarios, setComment } from '../../../services/api';
import useStyles from './styles';

function Comments() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [name, setName] = useState('');
  const { product, listModal, createModal, comment: comm } = useSelector(
    state => state.comment
  );

  useEffect(() => {
    const get = async pro => {
      try {
        const data = await comentarios(pro.produto_id);
        setList(data);
      } catch (error) {
        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    };

    if (product && listModal) get(product);
  }, [product, listModal]);

  useEffect(() => {
    setName(comm ? comm.name : '');
  }, [comm]);

  const hanldeClose = () => {
    dispatch({ type: '@comment/CANCEL' });
  };

  const hanldeComment = comment => {
    dispatch({
      type: '@item/SET_COMMENT',
      data: { comment },
    });
    dispatch({ type: '@comment/CANCEL' });
  };

  const hanldeSetComment = async () => {
    const text = `${name}`;
    if (text.trim().length <= 3) return false;

    dispatch({ type: '@data/LOADING' });
    try {
      const data = await setComment({ ...comm, name });

      if (data && data.success) {
        const { data: comment } = data;
        hanldeComment(comment);
      } else if (data && !data.success) {
        enqueueSnackbar(data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Houve um erro inesperado, Tente novamente', {
          variant: 'error',
        });
      }
      dispatch({ type: '@data/LOADING' });
    } catch (error) {
      dispatch({ type: '@data/LOADING' });
      enqueueSnackbar(
        'Houve uma falha ao tentar enviar os dados. Tente novamente',
        { variant: 'error' }
      );
    }

    return false;
  };

  return (
    <>
      <Drawer anchor="right" open={listModal} onClose={hanldeClose}>
        <div className={classes.root}>
          <ul className={classes.list}>
            {list &&
              list.map(comment => (
                <li
                  key={comment.comment_id}
                  className={classes.item}
                  onClick={() => hanldeComment(comment)}
                >
                  <span>{comment.name}</span>
                </li>
              ))}
          </ul>

          <div className={classes.footer}>
            <ArrowBackIos onClick={hanldeClose} />
            <h1
              onClick={() => {
                dispatch({ type: '@comment/CREATE', data: { product } });
              }}
            >
              criar
            </h1>
          </div>
        </div>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={createModal}
        onClose={() => {
          dispatch({ type: '@comment/CREATE_CANCEL' });
        }}
      >
        <div className={classes.modal}>
          <div className={classes.header}>
            <span>novo</span>
            <span>{`${name.length} / 100`}</span>
          </div>
          <div className={classes.comment}>
            <textarea
              name="comment"
              id="comment"
              cols="30"
              rows="6"
              value={name}
              maxLength="100"
              onChange={event => setName(event.target.value)}
              autoFocus
            />
          </div>
          <button
            type="button"
            className={classes.confirm}
            onClick={hanldeSetComment}
          >
            confirmar
          </button>
        </div>
      </Drawer>
    </>
  );
}

export default Comments;
