import React from 'react';

import Header from './Header';
import Body from './Body';
import Menu from './Menu';
import Comanda from './Body/Comanda';
import ComandaSettings from './Body/ComandaSettings';
import Categories from '../Categories';
import Produtcs from '../Produtcs';
import Item from '../Item';
import Comments from '../Comments';
import Order from '../Order';

function Dashboard() {
  return (
    <>
      <Header />
      <Body />
      <Menu />
      <Comanda />
      <ComandaSettings />
      <Categories />
      <Produtcs />
      <Item />
      <Comments />
      <Order />
    </>
  );
}

export default Dashboard;
